function createSimpleElemnts(list, wrapper, classN) {
    // <div class="swiper-button-next"></div>
    // <div class="swiper-button-prev"></div>
    // <div class="swiper-pagination"></div>
    let newOffertSwiper = document.createElement("div");
    newOffertSwiper.classList.add("offertSwiper");

    let newSwiper = document.createElement("div");
    newSwiper.classList.add("swiper");

    let newSwiperWrapper = document.createElement("div");
    newSwiperWrapper.className = "swiper-wrapper";


    let navWrapper = document.createElement("div");
    navWrapper.className = "swiper-nav";

    let sbNext = document.createElement("div");
    let sbPrev = document.createElement("div");
    let sPagination = document.createElement("div");

    sbNext.classList.add("swiper-button-next");
    sbPrev.classList.add("swiper-button-prev");
    sPagination.classList.add("swiper-scrollbar");

    newSwiper.prepend(newSwiperWrapper);
    navWrapper.prepend(sbNext);
    navWrapper.prepend(sPagination);
    navWrapper.prepend(sbPrev);


    newOffertSwiper.prepend(newSwiper);
    newOffertSwiper.append(navWrapper);
    wrapper.prepend(newOffertSwiper);

    let time = 0;
    let products = list;
    for (i = 0; i < products.length; i++) {
        let product = products[i];
        time += 80;

        let elLi = document.createElement("div");
        elLi.className = classN;

        let elItem = document.createElement("div");
        elItem.className = "product__item";

        let elA = document.createElement("a");
        elA.className = "product__link";
        elA.href = product.url;

        let elFigure = document.createElement("figure");
        elFigure.className = "product__figure";
        if (product.zdjecie) {
            let elImage = document.createElement("img");
            elImage.src = product.zdjecie;
            elImage.alt = product.nazwa;
            elFigure.appendChild(elImage);
        }

        let elPrB = document.createElement("div");
        elPrB.classList = "product__txt";

        if (product.nazwa) {
            let elh3 = document.createElement("h3");
            elh3.className = "product__title";
            elh3.textContent = product.nazwa;
            elPrB.appendChild(elh3);
        }


        if (product.opis_skrocony) {
            let elPrP = document.createElement("p");
            elPrP.classList = "product__summary";
            elPrP.textContent = product.opis_skrocony;
            elPrB.appendChild(elPrP);
        }

        elA.appendChild(elFigure);
        elA.appendChild(elPrB);

        elItem.appendChild(elA);
        elLi.appendChild(elItem);

        newSwiperWrapper.appendChild(elLi);
        setTimeout(function () {
            elLi.classList.remove("product__item--op0");
        }, time);
    }

    const swiper = new Swiper(newOffertSwiper.querySelector(".swiper"), {
        loop: false,
        slidesPerView: "auto",
        spaceBetween: 0,
        freeMode: true,
        breakpoints: {
            992: {
                spaceBetween: 10,
            },
        },

        scrollbar: {
            el: newOffertSwiper.querySelector(".swiper-scrollbar"),
            hide: false,
            draggable: true,
        },
        navigation: {
            nextEl: newOffertSwiper.querySelector(".swiper-button-next"),
            prevEl: newOffertSwiper.querySelector(".swiper-button-prev")
        },
    });


}


//dodawanie produktów do tab-strona główna


function createTabElements(data, el) {

    let id = el.getAttribute("aria-controls");
    let wrapper = document.getElementById(id);

    let products = JSON.parse(data);

    if (products.length > 0) {
        if (wrapper.querySelector(".alert")) {
            wrapper.querySelector(".alert").remove();
        }

        createSimpleElemnts(products, wrapper, "swiper-slide product__item--op0");

    } else {
        // if (wrapper.querySelector(".btn-all-cat")) {
        //     wrapper.querySelector(".btn-all-cat").remove();
        // }
        let noEl = document.createElement("div");
        noEl.className = "alert alert-grey";
        noEl.textContent = "W wybranej przez Ciebie kategorii nie ma jeszcze produktów";
        wrapper.prepend(noEl);
    }


}


if (document.querySelectorAll(".tabs__nav-trigger")) {
    let tabBtns = document.querySelectorAll(".offertSwiperNav .tabs__nav-trigger");
    for (var i = 0; i < tabBtns.length; i++) {
        tabBtns[i].addEventListener("click", function (e) {
            console.log("xxxxxxxxxxx");
            if (!this.classList.contains("js--add")) {
                let el = this;
                let category = this.getAttribute("data-category");
                let token = document.querySelector("meta[name='csrf-token']").getAttribute("content");
                dataToSend = new FormData();
                dataToSend.set('kategoria', category);
                dataToSend.set('__csrf__token__', token);
                makeRequest('/a/?kategoria=' + category, "GET", dataToSend, createTabElements, el);
                this.classList.add("js--add");
            }
        });

        if (!tabBtns[0].classList.contains("js--add")) {
            tabBtns[0].click();
        }
    }
}

if (document.getElementById("configuration__move")) {

    let configurationLeft = (document.querySelector(".configuration-clipping-wrapper").getBoundingClientRect()).left;
    let configurationRight = document.querySelector(".configuration__right").clientWidth;


    if (window.innerWidth > 576) {
        document.querySelector(".configuration-clipping-wrapper").style.width = "50%";
    } else {
        document.querySelector(".configuration-clipping-wrapper").style.width = "100px";
    }

    let isDown = false;
    let offsetLeft = 0;

    document.querySelector("#configuration__move").addEventListener("mousedown", function (event) {
        isDown = true;
        offsetLeft = event.pageX - configurationLeft;

    }, true);
    document.addEventListener('mouseup', function () {
        isDown = false;
    }, true);
    document.addEventListener('mousemove', function (event) {
        event.preventDefault();
        if (isDown) {
            offsetLeft = event.pageX - configurationLeft;
            if (offsetLeft > 0 && offsetLeft < configurationRight) {
                document.querySelector(".configuration-clipping-wrapper").style.width = offsetLeft + "px";
            }
        }
    }, true);

    document.querySelector(".configuration__right").addEventListener("touchmove", function (event) {
        offsetLeft = event.touches[0].clientX;
        document.querySelector(".configuration-clipping-wrapper").style.width = offsetLeft + "px";

    });


    // document.querySelector("#configuration__move").addEventListener("touchmove", function(event) {
    //     pointerX = event.touches[0].clientX - configurationLeft;;
    //     pointerY = event.pageY;
    //     if (pointerX > 0) { document.querySelector(".configuration-clipping-wrapper").style.width = pointerX + "px"; }

    // });


}
